import { useEffect, useState } from "react";
import { INPUT_SIZE, SeedType, decodeData } from "../decoder";

const useSeed = () => {
  const [seed, setSeed] = useState<SeedType | null>(null);

  useEffect(() => {
    const inputNumbers = [];

    for (let i = 0; i < INPUT_SIZE; i++) {
      inputNumbers.push(Math.random());
    }

    setSeed(decodeData(inputNumbers.join(",")));
  }, []);

  return seed;
};

export default useSeed;
