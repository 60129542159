const fonts: string[] = [
  "'Anton', sans-serif",
  "'Bebas Neue', sans-serif",
  "'Dancing Script', cursive",
  "'Exo 2', sans-serif",
  "'Inter', sans-serif",
  "'Josefin Sans', sans-serif",
  "'Kanit', sans-serif",
  "'Lato', sans-serif",
  "'Libre Baskerville', serif",
  "'Lobster', sans-serif",
  "'Lora', serif",
  "'Merriweather', serif",
  "'Montserrat', sans-serif",
  "'Noto Sans', sans-serif",
  "'Nunito', sans-serif",
  "'Nunito Sans', sans-serif",
  "'Open Sans', sans-serif",
  "'Oswald', sans-serif",
  "'Pacifico', cursive",
  "'Playfair Display', serif",
  "'Poppins', sans-serif",
  "'PT Sans', sans-serif",
  "'Raleway', sans-serif",
  "'Roboto', sans-serif",
  "'Roboto Condensed', sans-serif",
  "'Roboto Mono', monospace",
  "'Roboto Slab', serif",
  "'Source Code Pro', monospace",
  "'Teko', sans-serif",
  "'Ubuntu', sans-serif",
];

export default fonts;
