import image01 from "../assets/hero-image/hero-01.png";
import image02 from "../assets/hero-image/hero-02.png";

interface TextWithLink {
  text: string;
  link: string;
}

interface ContentType {
  titles: string[];
  subtitles: string[];
  flair: string[];
  primaryButtonTexts: string[];
  primaryButtonLink: string;
  secondaryButtonTexts: string[];
  secondaryButtonLink: string;
  transparentImages: string[];
  logo: string;
  links: TextWithLink[];
}

const content: ContentType = {
  titles: [
    "We've Got Cancer",
    "A Journey of Love and Survival",
    "The Road Less Traveled",
    "Navigating Cancer Together",
    "Fighting the Invisible Enemy",
  ],
  subtitles: [
    "Unveil the Untold Story of Conquering Multiple Myeloma",
    "Discover How One Couple Faced Multiple Myeloma and Found Remission",
    "A Firsthand Account of Medical Treatments, Side Effects, and Life Beyond",
  ],
  flair: ["Hope & Strength", "Resilient Love", "Inspiring Journey"],
  primaryButtonTexts: [
    "Buy on Amazon Now",
    "Get Your Copy Today",
    "Unlock the Journey",
    "Read Our Story",
    "Purchase Now",
    "Buy Book",
    "Read Now",
    "Order Here",
  ],
  primaryButtonLink: "https://amzn.to/3s4myXl",
  secondaryButtonLink: "https://amzn.to/46YWsUr",
  secondaryButtonTexts: [
    "Meet the Author",
    "About Kushla Rolls",
    "Author Profile",
    "Discover Kushla",
    "Kushla on Amazon",
    "More by Kushla",
    "Explore the Author",
    "Who is Kushla?",
  ],
  transparentImages: [image01, image02],
  logo: "Modern Mind",
  links: [
    {
      text: "Book",
      link: "https://www.amazon.co.uk/Weve-Got-Cancer-challenging-diagnosis/dp/0473535971",
    },
    {
      text: "Author",
      link: "https://www.amazon.co.uk/Kushla-Rolls/e/B08RCB6HP9/ref=dp_byline_cont_book_1",
    },
  ],
};

export default content;
