import styled from "styled-components";

import Button from "./Button";
import { SeedType } from "../app/decoder";
import content from "../app/content";

const StyledHero = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--heroPaddingTopBottom) var(--heroPaddingLeftRight);
  flex: 1;
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
`;

const Image = styled.img`
  height: var(--imageHeightPercent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: var(--heroAlignItems);
`;

const Title = styled.h1`
  font-size: var(--titleFontSize);
  font-weight: var(--titleFontWeight);
  text-align: var(--heroTextAlign);
`;

const SubTitle = styled.h2`
  font-size: var(--subTitleFontSize);
  font-weight: var(--subTitleFontWeight);
  text-align: var(--heroTextAlign);
  opacity: var(--subOpacity);
  margin-top: var(--heroSubTitleTopMargin);
`;

const Flair = styled.h3`
  font-size: var(--flairFontSize);
  font-weight: var(--flairFontWeight);
  text-align: var(--heroTextAlign);
  margin-bottom: var(--heroFlairBottomMargin);
`;

const ButtonContainer = styled.div`
  margin-top: var(--heroButtonTopMargin);
`;

interface Props {
  seed: SeedType;
}

const Hero = ({ seed }: Props) => {
  return (
    <StyledHero>
      {!seed.heroImageOnRight && seed.showImage && (
        <ImageContainer>
          <Image src={seed.image} alt="Hero image" />
        </ImageContainer>
      )}
      <ContentContainer>
        <Content>
          {seed.showFlair && <Flair>{seed.flair}</Flair>}
          <Title>{seed.title}</Title>
          {seed.showSubTitle && <SubTitle>{seed.subTitle}</SubTitle>}
          <ButtonContainer>
            <Button primary seed={seed} link={content.primaryButtonLink}>
              {seed.primaryButtonText}
            </Button>
          </ButtonContainer>
        </Content>
      </ContentContainer>
      {seed.heroImageOnRight && seed.showImage && (
        <ImageContainer>
          <Image src={seed.image} alt="Hero image" />
        </ImageContainer>
      )}
    </StyledHero>
  );
};

export default Hero;
