import styled from "styled-components";

import Button from "./Button";
import content from "../app/content";
import { SeedType } from "../app/decoder";
import { logConversion } from "../app/db";
import { visitedAuthorPage, visitedBookPage } from "../app/ga";

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Logo = styled.div`
  font-size: var(--logoFontSize);
  font-weight: var(--logoFontWeight);
  margin-right: var(--logoRightMargin);
`;

const NavItems = styled.div`
  align-items: center;

  display: var(--navItemsDisplay);
  flex: var(--navItemFlex);
  position: var(--navItemsPosition);
  top: var(--navItemsTop);
  left: var(--navItemsLeft);
  transform: var(--navItemsTransform);
`;

const NavItem = styled.a`
  cursor: pointer;

  margin: 0 var(--navItemMargin);
  font-size: var(--navItemFontSize);
  font-weight: var(--navItemFontWeight);
  text-decoration: var(--navItemTextDecoration);
  opacity: var(--subOpacity);
`;

interface Props {
  seed: SeedType;
}

const Header = ({ seed }: Props) => {
  return (
    <StyledHeader>
      <Logo>{content.logo}</Logo>
      <NavItems>
        {content.links.map((link) => {
          return (
            <NavItem
              key={link.text}
              href={link.link}
              target="_blank"
              onClick={() => {
                logConversion(seed.string);

                if (link.link === content.primaryButtonLink) {
                  visitedBookPage();
                } else {
                  visitedAuthorPage();
                }
              }}
            >
              {link.text}
            </NavItem>
          );
        })}
      </NavItems>
      {seed.showHeaderButton && (
        <Button
          seed={seed}
          primary={seed.headerButtonPrimary}
          link={content.secondaryButtonLink}
        >
          {seed.secondaryButtonText}
        </Button>
      )}
    </StyledHeader>
  );
};

export default Header;
