import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import hashString from "./crypto";

const firebaseConfig = {
  apiKey: "AIzaSyCMOwLJJ4b7afEYrSnOli_ZodvogHAsSIU",
  authDomain: "we-ve-got-cancer.firebaseapp.com",
  projectId: "we-ve-got-cancer",
  storageBucket: "we-ve-got-cancer.appspot.com",
  messagingSenderId: "775313312337",
  appId: "1:775313312337:web:b67efeab56e6e190a40670",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const logVisit = async (seed: string) => {
  const hash = await hashString(seed);
  await setDoc(doc(db, "visits", hash), {
    seed,
    converted: false,
  });
};

export const logConversion = async (seed: string) => {
  const hash = await hashString(seed);
  await setDoc(doc(db, "visits", hash), {
    seed,
    converted: true,
  });
};
