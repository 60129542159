import styled from "styled-components";
import { ReactNode } from "react";
import { logConversion } from "../app/db";
import { SeedType } from "../app/decoder";
import content from "../app/content";
import { visitedAuthorPage, visitedBookPage } from "../app/ga";

interface ButtonProps {
  primary?: boolean;
}

const StyledButton = styled.a<ButtonProps>`
  cursor: pointer;

  padding: var(--buttonPaddingTopBottom) var(--buttonPaddingRightLeft);
  font-size: var(--buttonFontSize);
  font-weight: var(--buttonFontWeight);
  border-radius: var(--buttonBorderRadius);
  border: solid var(--buttonBorderWidth) var(--primary);

  color: ${(props) => (props.primary ? "var(--background)" : "var(--primary)")};
  background: ${(props) => (props.primary ? "var(--primary)" : "none")};
`;

interface Props {
  children: ReactNode;
  seed: SeedType;
  link: string;
  primary?: boolean;
}

const Button = ({ children, seed, link, primary }: Props) => {
  return (
    <StyledButton
      primary={primary}
      href={link}
      target="_blank"
      onClick={() => {
        logConversion(seed.string);

        if (link === content.primaryButtonLink) {
          visitedBookPage();
        } else {
          visitedAuthorPage();
        }
      }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
