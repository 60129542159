import content from "./content";

export const INPUT_SIZE = 119;

// 0: darkMode
// 1: backgroundColorHue
// 2: backgroundColorSaturation
// 3: backgroundColorBrightness
// 4: mainColorHue
// 5: mainColorSaturation
// 6: mainColorBrightness
// 7: pagePaddingTopBottom
// 8: pagePaddingLeftRight
// 9: logoFontSize
// 10: logoFontWeight
// 11: Font: Roboto
// 12: Font: Open Sans
// 13: Font: Montserrat
// 14: Font: Lato
// 15: Font: Poppins
// 16: Font: Roboto Condensed
// 17: Font: Inter
// 18: Font: Roboto Mono
// 19: Font: Oswald
// 20: Font: Raleway
// 21: Font: Noto Sans
// 22: Font: Nunito Sans
// 23: Font: Roboto Slab
// 24: Font: Nunito
// 25: Font: Playfair Display
// 26: Font: Ubuntu
// 27: Font: Merriweather
// 28: Font: PT Sans
// 29: Font: Kanit
// 30: Font: Lora
// 31: Font: Josefin Sans
// 32: Font: Libre Baskerville
// 33: Font: Bebas Neue
// 34: Font: Source Code Pro
// 35: Font: Anton
// 36: Font: Dancing Script
// 37: Font: Pacifico
// 38: Font: Lobster
// 39: Font: Exo 2
// 40: Font: Teko
// 41: navItemFontSize
// 42: navItemFontWeight
// 43: navItemUndeline
// 44: navItemMargin
// 45: navItemsFlex
// 46: logoRightMargin
// 47: Absolute center nav items
// 48: Show nav items
// 49: subOpacity
// 50: primaryColorHue
// 51: primaryColorSaturation
// 52: primaryColorBrightness
// 53: buttonFontSize
// 54: buttonFontWeight
// 55: buttonPaddingTopBottom
// 56: buttonPaddingRightLeft
// 57: buttonBorderRadius
// 58: buttonBorderWidth
// 59: headerButtonPrimary
// 60: showHeaderButton
// 61: heroPaddingTopBottom
// 62: heroPaddingLeftRight
// 63: showImage
// 64: showSubTitle
// 65: showFlair
// 66: heroTextLeftAlign
// 67: heroTextCenterAlign
// 68: titleFontSize
// 69: titleFontWeight
// 70: subTitleFontSize
// 71: subTitleFontWeight
// 72: flairFontSize
// 73: flairFontWeight
// 74: heroImageOnRight
// 75: imageHeightPercent
// 76: heroButtonTopMargin
// 78: heroSubTitleTopMargin
// 79: heroFlairBottomMargin
// 90: Title 01
// 91: Title 02
// 92: Title 03
// 93: Title 04
// 94: Title 05
// 95: Subtitle 01
// 96: Subtitle 02
// 97: Subtitle 03
// 98: Flair 01
// 99: Flair 02
// 100: Flair 03
// 101: Primary Button 01
// 102: Primary Button 02
// 103: Primary Button 03
// 104: Primary Button 04
// 105: Primary Button 05
// 106: Primary Button 06
// 107: Primary Button 07
// 108: Primary Button 08
// 109: Secondary Button 01
// 110: Secondary Button 02
// 111: Secondary Button 03
// 112: Secondary Button 04
// 113: Secondary Button 05
// 114: Secondary Button 06
// 115: Secondary Button 07
// 116: Secondary Button 08
// 117: Image 01
// 118: Image 02

const MIN_FONT_WEIGHT = 100;
const MAX_FONT_WEIGHT = 900;

const MIN_PAGE_PADDING_TOP_BOTTOM = 5;
const MAX_PAGE_PADDING_TOP_BOTTOM = 100;
const MIN_PAGE_PADDING_LEFT_RIGHT = 10;
const MAX_PAGE_PADDING_LEFT_RIGHT = 100;
const MIN_LOGO_SIZE = 12;
const MAX_LOGO_SIZE = 50;
const MIN_NAV_ITEM_SIZE = 12;
const MAX_NAV_ITEM_SIZE = 30;
const MIN_NAV_ITEM_MARGIN = 4;
const MAX_NAV_ITEM_MARGIN = 40;
const MIN_SECONDARY_OPACITY = 0.5;
const MAX_SECONDARY_OPACITY = 1;
const MAX_BACKGROUND_LIGHTNESS = 36;
const MAX_TEXT_DARKNESS = 15;
const MIN_PRIMARY_SATURATION = 0;
const MAX_PRIMARY_LIGHTNESS = 63;
const MIN_BUTTON_FONT_SIZE = 12;
const MAX_BUTTON_FONT_SIZE = 30;
const MIN_BUTTON_PADDING_TOP_BOTTOM = 1;
const MAX_BUTTON_PADDING_TOP_BOTTOM = 9;
const MIN_BUTTON_PADDING_RIGHT_LEFT = 5;
const MAX_BUTTON_PADDING_RIGHT_LEFT = 34;
const MIN_BUTTON_BORDER_RADIUS = 0;
const MAX_BUTTON_BORDER_RADIUS =
  (MAX_BUTTON_PADDING_TOP_BOTTOM * 2 + MAX_BUTTON_FONT_SIZE) / 2;
const MAX_BUTTON_BORDER_WIDTH = 6;
const MIN_HERO_PADDING_TOP_BOTTOM = 5;
const MAX_HERO_PADDING_TOP_BOTTOM = 100;
const MIN_HERO_PADDING_LEFT_RIGHT = 0;
const MAX_HERO_PADDING_LEFT_RIGHT = 100;
const MIN_TITLE_FONT_SIZE = 50;
const MAX_TITLE_FONT_SIZE = 115;
const MIN_SUB_TITLE_FONT_SIZE = 12;
const MAX_SUB_TITLE_FONT_SIZE = 30;
const MIN_FLAIR_FONT_SIZE = 12;
const MAX_FLAIR_FONT_SIZE = 30;
const MIN_IMAGE_HEIGHT_PERCENT = 35;
const MAX_IMAGE_HEIGHT_PERCENT = 70;
const MIN_HERO_BUTTON_TOP_MARGIN = 5;
const MAX_HERO_BUTTON_TOP_MARGIN = 40;
const MIN_HERO_SUB_TITLE_TOP_MARGIN = 5;
const MAX_HERO_SUB_TITLE_TOP_MARGIN = 40;
const MIN_HERO_FLAIR_BOTTOM_MARGIN = 5;
const MAX_HERO_FLAIR_BOTTOM_MARGIN = 40;

export interface SeedType {
  string: string;

  darkMode: boolean;

  backgroundColor: string;
  mainColor: string;
  primaryColor: string;
  subOpacity: number;

  pagePaddingTopBottom: number;
  pagePaddingLeftRight: number;
  navItemMargin: number;
  navItemFlex: string;
  logoRightMargin: number;
  absoluteCenterNavItems: boolean;
  showNavItems: boolean;
  buttonPaddingTopBottom: number;
  buttonPaddingRightLeft: number;
  buttonBorderRadius: number;
  buttonBorderWidth: number;
  headerButtonPrimary: boolean;
  showHeaderButton: boolean;
  heroPaddingTopBottom: number;
  heroPaddingLeftRight: number;
  heroButtonTopMargin: number;
  heroSubTitleTopMargin: number;
  heroFlairBottomMargin: number;

  showImage: boolean;
  showSubTitle: boolean;
  showFlair: boolean;
  heroImageOnRight: boolean;
  imageHeightPercent: number;

  fontIndex: number;
  logoFontSize: number;
  logoFontWeight: number;
  navItemFontSize: number;
  navItemFontWeight: number;
  navItemTextDecoration: string;
  buttonFontSize: number;
  buttonFontWeight: number;
  titleFontSize: number;
  titleFontWeight: number;
  subTitleFontSize: number;
  subTitleFontWeight: number;
  flairFontSize: number;
  flairFontWeight: number;
  heroTextLeftAlign: boolean;
  heroTextCenterAlign: boolean;

  title: string;
  subTitle: string;
  flair: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  image: string;
}

const optionIndex = (input: number[]) => {
  let largest = 0;
  let largestIndex = 0;
  input.forEach((v, i) => {
    if (v > largest) {
      largest = v;
      largestIndex = i;
    }
  });
  return largestIndex;
};

const value = (input: number, scale: number = 100) => {
  return Math.round(input * scale);
};

const getFontWeight = (input: number) => {
  return minMax(input, MIN_FONT_WEIGHT / 100, MAX_FONT_WEIGHT / 100) * 100;
};

const getTextDecoration = (input: number) => {
  return input > 0.5 ? "underline" : "none";
};

const minMax = (input: number, min: number, max: number, round = true) => {
  const base = input * (max - min) + min;
  return round ? Math.round(base) : base;
};

const getColor = (
  hue: number,
  saturation: number,
  brightness: number,
  darkMode: boolean,
  background: boolean,
  range: number = 50,
  minSaturation = 0
): string => {
  const light = darkMode ? !background : background;
  const mod = light ? 100 - range : 0;
  return `hsl(${value(hue, 365)},${minMax(saturation, minSaturation, 100)}%,${
    value(brightness, range) + mod
  }%)`;
};

export const decodeData = (input: string): SeedType => {
  const values = input
    .replace(" ", "")
    .split(",")
    .map((v) => Number(v));

  // Validating input
  if (values.length !== INPUT_SIZE) {
    throw new Error("Invalid input");
  }

  const darkMode = values[0] > 0.5;

  return {
    string: input,
    darkMode,
    backgroundColor: getColor(
      values[1],
      values[2],
      values[3],
      darkMode,
      true,
      MAX_BACKGROUND_LIGHTNESS
    ),
    mainColor: getColor(
      values[4],
      values[5],
      values[6],
      darkMode,
      false,
      MAX_TEXT_DARKNESS
    ),
    primaryColor: getColor(
      values[50],
      values[51],
      values[52],
      darkMode,
      false,
      MAX_PRIMARY_LIGHTNESS,
      MIN_PRIMARY_SATURATION
    ),
    subOpacity: minMax(
      values[49],
      MIN_SECONDARY_OPACITY,
      MAX_SECONDARY_OPACITY,
      false
    ),
    pagePaddingTopBottom: minMax(
      values[7],
      MIN_PAGE_PADDING_TOP_BOTTOM,
      MAX_PAGE_PADDING_TOP_BOTTOM
    ),
    pagePaddingLeftRight: minMax(
      values[8],
      MIN_PAGE_PADDING_LEFT_RIGHT,
      MAX_PAGE_PADDING_LEFT_RIGHT
    ),
    fontIndex: optionIndex(values.slice(11, 41)),
    logoFontSize: minMax(values[9], MIN_LOGO_SIZE, MAX_LOGO_SIZE),
    logoFontWeight: getFontWeight(values[10]),
    navItemFontSize: minMax(values[41], MIN_NAV_ITEM_SIZE, MAX_NAV_ITEM_SIZE),
    navItemFontWeight: getFontWeight(values[42]),
    navItemTextDecoration: getTextDecoration(values[43]),
    navItemMargin: minMax(values[44], MIN_NAV_ITEM_MARGIN, MAX_NAV_ITEM_MARGIN),
    navItemFlex: values[45] > 0.5 ? "1" : "none",
    logoRightMargin:
      values[45] > 0.5
        ? minMax(values[46], MIN_NAV_ITEM_MARGIN * 2, MAX_NAV_ITEM_MARGIN * 2)
        : 0,
    absoluteCenterNavItems: values[45] > 0.5 ? false : values[47] > 0.5,
    showNavItems: values[48] > 0.5,
    buttonFontSize: minMax(
      values[53],
      MIN_BUTTON_FONT_SIZE,
      MAX_BUTTON_FONT_SIZE
    ),
    buttonFontWeight: getFontWeight(values[54]),
    buttonPaddingTopBottom: minMax(
      values[55],
      MIN_BUTTON_PADDING_TOP_BOTTOM,
      MAX_BUTTON_PADDING_TOP_BOTTOM
    ),
    buttonPaddingRightLeft: minMax(
      values[56],
      MIN_BUTTON_PADDING_RIGHT_LEFT,
      MAX_BUTTON_PADDING_RIGHT_LEFT
    ),
    buttonBorderRadius: minMax(
      values[57],
      MIN_BUTTON_BORDER_RADIUS,
      MAX_BUTTON_BORDER_RADIUS
    ),
    buttonBorderWidth: minMax(values[58], 0, MAX_BUTTON_BORDER_WIDTH),
    headerButtonPrimary: values[59] > 0.5,
    showHeaderButton: values[60] > 0.5,
    heroPaddingTopBottom: minMax(
      values[61],
      MIN_HERO_PADDING_TOP_BOTTOM,
      MAX_HERO_PADDING_TOP_BOTTOM
    ),
    heroPaddingLeftRight: minMax(
      values[62],
      MIN_HERO_PADDING_LEFT_RIGHT,
      MAX_HERO_PADDING_LEFT_RIGHT
    ),
    showImage: values[63] > 0.5,
    showSubTitle: values[64] > 0.5,
    showFlair: values[65] > 0.5,
    heroTextLeftAlign: values[66] > 0.5,
    heroTextCenterAlign: values[67] > 0.5,
    titleFontSize: minMax(
      values[68],
      MIN_TITLE_FONT_SIZE,
      values[63] > 0.5 ? MAX_TITLE_FONT_SIZE : MAX_TITLE_FONT_SIZE * 2
    ),
    titleFontWeight: getFontWeight(values[69]),
    subTitleFontSize: minMax(
      values[70],
      MIN_SUB_TITLE_FONT_SIZE,
      MAX_SUB_TITLE_FONT_SIZE
    ),
    subTitleFontWeight: getFontWeight(values[71]),
    flairFontSize: minMax(values[72], MIN_FLAIR_FONT_SIZE, MAX_FLAIR_FONT_SIZE),
    flairFontWeight: getFontWeight(values[73]),
    heroImageOnRight: values[74] > 0.5,
    imageHeightPercent: minMax(
      values[75],
      MIN_IMAGE_HEIGHT_PERCENT,
      MAX_IMAGE_HEIGHT_PERCENT
    ),
    heroButtonTopMargin: minMax(
      values[76],
      MIN_HERO_BUTTON_TOP_MARGIN,
      MAX_HERO_BUTTON_TOP_MARGIN
    ),
    heroSubTitleTopMargin: minMax(
      values[77],
      MIN_HERO_SUB_TITLE_TOP_MARGIN,
      MAX_HERO_SUB_TITLE_TOP_MARGIN
    ),
    heroFlairBottomMargin: minMax(
      values[78],
      MIN_HERO_FLAIR_BOTTOM_MARGIN,
      MAX_HERO_FLAIR_BOTTOM_MARGIN
    ),
    title: content.titles[optionIndex(values.slice(90, 95))],
    subTitle: content.subtitles[optionIndex(values.slice(95, 98))],
    flair: content.flair[optionIndex(values.slice(98, 101))],
    primaryButtonText:
      content.primaryButtonTexts[optionIndex(values.slice(101, 109))],
    secondaryButtonText:
      content.secondaryButtonTexts[optionIndex(values.slice(109, 117))],
    image: content.transparentImages[optionIndex(values.slice(117, 119))],
  };
};
