import { createGlobalStyle } from "styled-components";
import { SeedType } from "../app/decoder";
import fonts from "../app/font-options";

const GlobalStyle = createGlobalStyle<Props>`
    :root {
        /* Colors */
        --background: ${(props) => props.data.backgroundColor};
        --main: ${(props) => props.data.mainColor};
        --primary: ${(props) => props.data.primaryColor};
        --subOpacity: ${(props) => props.data.subOpacity};

        /* Padding */
        --pagePaddingTopBottom: ${(props) => props.data.pagePaddingTopBottom}px;
        --pagePaddingLeftRight: ${(props) => props.data.pagePaddingLeftRight}px;
        --navItemMargin: ${(props) => props.data.navItemMargin}px;
        --navItemFlex: ${(props) => props.data.navItemFlex};
        --logoRightMargin: ${(props) => props.data.logoRightMargin}px;
        --navItemsPosition: ${(props) =>
          props.data.absoluteCenterNavItems ? "absolute" : "relative"};
        --navItemsTop: ${(props) =>
          props.data.absoluteCenterNavItems ? "50%" : "auto"};
        --navItemsLeft: ${(props) =>
          props.data.absoluteCenterNavItems ? "50%" : "auto"};
        --navItemsTransform: ${(props) =>
          props.data.absoluteCenterNavItems ? "translate(-50%, -50%)" : "none"};
        --navItemsDisplay: ${(props) =>
          props.data.showNavItems ? "flex" : "none"};
        --buttonPaddingTopBottom: ${(props) =>
          props.data.buttonPaddingTopBottom}px;
        --buttonPaddingRightLeft: ${(props) =>
          props.data.buttonPaddingRightLeft}px;
        --buttonBorderRadius: ${(props) => props.data.buttonBorderRadius}px;
        --buttonBorderWidth: ${(props) => props.data.buttonBorderWidth}px;
        --heroPaddingTopBottom: ${(props) => props.data.heroPaddingTopBottom}px;
        --heroPaddingLeftRight: ${(props) => props.data.heroPaddingLeftRight}px;
        --imageHeightPercent: ${(props) => props.data.imageHeightPercent}dvh;
        --heroButtonTopMargin: ${(props) => props.data.heroButtonTopMargin}px;
        --heroSubTitleTopMargin: ${(props) =>
          props.data.heroSubTitleTopMargin}px;
        --heroFlairBottomMargin: ${(props) =>
          props.data.heroFlairBottomMargin}px;

        /* Show/Hide */
        --imageDisplay: ${(props) => (props.data.showImage ? "flex" : "none")};
        --subTitleDisplay: ${(props) =>
          props.data.showSubTitle ? "flex" : "none"};
        --flairDisplay: ${(props) => (props.data.showFlair ? "flex" : "none")};

        /* Font */
        --logoFontSize: ${(props) => props.data.logoFontSize}px;
        --logoFontWeight: ${(props) => props.data.logoFontWeight};
        --navItemFontSize: ${(props) => props.data.navItemFontSize}px;
        --navItemFontWeight: ${(props) => props.data.navItemFontWeight};
        --navItemTextDecoration: ${(props) => props.data.navItemTextDecoration};
        --buttonFontSize: ${(props) => props.data.buttonFontSize}px;
        --buttonFontWeight: ${(props) => props.data.buttonFontWeight};
        --titleFontSize: ${(props) => props.data.titleFontSize}px;
        --titleFontWeight: ${(props) => props.data.titleFontWeight};
        --subTitleFontSize: ${(props) => props.data.subTitleFontSize}px;
        --subTitleFontWeight: ${(props) => props.data.subTitleFontWeight};
        --flairFontSize: ${(props) => props.data.flairFontSize}px;
        --flairFontWeight: ${(props) => props.data.flairFontWeight};
        --heroAlignItems: ${(props) =>
          props.data.heroTextCenterAlign
            ? "center"
            : props.data.heroTextLeftAlign
            ? "flex-start"
            : "flex-end"};
        --heroTextAlign: ${(props) =>
          props.data.heroTextCenterAlign
            ? "center"
            : props.data.heroTextLeftAlign
            ? "left"
            : "right"};
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: var(--main);
        font-family: ${(props) => fonts[props.data.fontIndex]};
        line-height: 1;
    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;
        background: none;

        // Remove arrows from number input
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    a {
        text-decoration: none;
    }
`;

interface Props {
  data: SeedType;
}

const GlobalStyles = ({ data }: Props): JSX.Element => {
  return <GlobalStyle data={data} />;
};

export default GlobalStyles;
