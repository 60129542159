declare function gtag(...args: any[]): void;

export const visitedBookPage = () => {
  gtag("event", "conversion", {
    send_to: "AW-11382462625/ZTDJCLfe1-4YEKGxyrMq",
    event_callback: () => {},
  });
};

export const visitedAuthorPage = () => {
  gtag("event", "conversion", {
    send_to: "AW-11382462625/BN2JCLre1-4YEKGxyrMq",
    event_callback: () => {},
  });
};
