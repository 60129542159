const hashString = async (string: string) => {
  // Encode the string into a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(string);

  // Create a hash of the data
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};

export default hashString;
