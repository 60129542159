import styled from "styled-components";

import useSeed from "./app/hooks/seed";
import GlobalStyles from "./styles/GlobalStyles";
import Header from "./components/Header";
import Hero from "./components/Hero";
import { useEffect } from "react";
import { logVisit } from "./app/db";

const StyledApp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;

  background: var(--background);
  padding: var(--pagePaddingTopBottom) var(--pagePaddingLeftRight);
`;

const App = () => {
  const seed = useSeed();

  useEffect(() => {
    if (!seed) return;
    logVisit(seed.string);
  }, [seed]);

  if (!seed) return null;

  return (
    <>
      <GlobalStyles data={seed} />
      <StyledApp>
        <Header seed={seed} />
        <Hero seed={seed} />
      </StyledApp>
    </>
  );
};

export default App;
